<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card
            class="mx-auto"
            max-width="600"
        >
          <v-card-title
              class="blue-grey white--text"
          >
            <span class="title">Ping</span>
            <v-spacer></v-spacer>
            <v-btn
                :outlined="!isRunning"
                :color="!isRunning ? 'white' : 'error'"
                dark
                depressed
                @click="!isRunning ? start() : stop()"
            >
              {{ !isRunning ? 'START' : 'STOP' }}
            </v-btn>
          </v-card-title>
          <v-card-text class="py-0">
            <v-timeline dense>
              <v-slide-x-reverse-transition
                  group
                  hide-on-leave
              >
                <v-timeline-item
                    v-for="item in items"
                    :key="item.id"
                    :color="item.color"
                    small
                    fill-dot
                >
                  <v-alert
                      :value="true"
                      :color="item.color"
                      :icon="item.icon"
                      class="white--text"
                  >
                    {{ item.message }}
                  </v-alert>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import firebase from "firebase/app"
import axios from "axios"
// import "firebase/firestore"

const ICONS = {
  info: 'mdi-information',
  warning: 'mdi-alert',
  error: 'mdi-alert-circle',
  success: 'mdi-check-circle',
}

export default {
  name: "Ping",
  data: () => ({
    isRunning: false,
    items: [
      {
        id: 1,
        color: 'info',
        icon: ICONS.info,
        message: 'Click START button to start ping request.',
      },
    ],
    nonce: 2,
  }),

  beforeDestroy () {
    this.stop()
  },

  methods: {
    async addEvent () {
      const t1 = performance.now()

      try {
        const res = await axios.get('https://mss-ping.herokuapp.com/ping')
        console.log(res)

        const t2 = performance.now()
        this.items.unshift({
          id: this.nonce++,
          color: 'success',
          icon: ICONS.success,
          message: `Ping request has been responded with ${res.data.message} in ${Math.round(t2-t1)} ms`,
        })
      } catch (error) {
        const t2 = performance.now()
        this.items.unshift({
          id: this.nonce++,
          color: 'error',
          icon: ICONS.error,
          message: `Error pinging the target after ${Math.round(t2-t1)} ms (${error})`,
        })
      }

      if (this.nonce > 60) {
        this.items.pop()
      }
    },
    async start () {
      this.isRunning = true

      while(this.isRunning) {
        await this.addEvent()
        await this.delay(1000)
      }
    },
    stop () {
      this.isRunning = false
    },
    delay (duration) {
      return new Promise(resolve => setTimeout(resolve, duration))
    }
  },
}
</script>

<style scoped>

</style>